<template>
  <el-dialog
    :title="'Change Email'"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="" prop="current_email" :rules="validateEmail()">
            <el-input
              v-model="form.current_email"
              type="text"
              placeholder="Current email"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="" prop="new_email" :rules="validateEmail()">
            <el-input
              v-model="form.new_email"
              type="text"
              placeholder="New email"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="shouldShow = false">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="disableChange"
        @click="changeEmail"
        >Change</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import member from "@/requests/settings/members/member";

export default {
  name: "MemberEmailChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        current_email: "",
        new_email: "",
      },
      changing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disableChange() {
      return this.form.current_email === this.form.new_email;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.current_email = this.email;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    changeEmail() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.changing = true;
        member
          .email(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.changing = false;
              this.$emit("success", this.form.new_email);
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.changing = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss"></style>
